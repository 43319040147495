<template>
    <div class="row">
            <div class="col-md-4 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Cliente</h4>
                  <p class="card-description">

                  <p class="card-description">
                    <b>Pedido:</b> {{pedido.codigo}}<br>
                    <b>Data:</b> {{pedido.data}}
                  </p>

                    <p class="card-description">    
                    <b>Nome:</b> {{pedido.cliente.nome}}<br>
                    <b>E-mail:</b> {{pedido.cliente.email}}<br>
                    <b>Celular:</b> {{pedido.cliente.celular}}<br>
                  </p>

                  <p class="card-description">
                    <b>Status Pagamento:</b> {{status.pagamento}}<br>
                    <b>Status Pedido:</b> {{status.pedido}}
                  </p>

                </div>
              </div>
            </div>

            <div class="col-md-8 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Itens</h4>

                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Qtde</th>
                          <th>Descrição</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in itens" :key="item.id">
                          <td>{{item.qtde}}X</td>
                          <td>{{item.descricao}}</td>
                          <td>{{formatarValor(item.valor)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>


            <div class="col-md-4 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Valores</h4>
                  <p class="card-description">
                    <b>Valor Itens:</b> {{pedido.valor.total_formatado}}<br>
                    <b>Valor Frete:</b> {{pedido.valor.frete_formatado}}
                  </p>

                <hr>
                <p class="card-description">
                    <b>Valor Total Pedido:</b> {{pedido.valor.valor_total_pedido_formatado}}<br>
                    <b>Tipo Pagamento:</b> {{pedido.pagamento.descricao}}<br>
                  </p>


                </div>
              </div>
            </div>

            <div class="col-md-4 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Entrega</h4>
                  <p class="card-description">
                    <b>Tipo:</b> {{pedido.entrega.descricao}}
                  </p>

                <div v-if="pedido.cliente.endereco">
                    <hr>
                    <p class="card-description">
                        <b>Endereço:</b> {{pedido.cliente.endereco.logradouro}}, Nº{{pedido.cliente.endereco.numero}}<br>
                        <b>Complemento:</b> {{pedido.cliente.endereco.complemento}}<br>
                        <b>Bairro:</b> {{pedido.cliente.endereco.bairro}}<br>
                        <b>Cidade:</b> {{pedido.cliente.endereco.cidade}}<br>
                        <b>Estado:</b> {{pedido.cliente.endereco.estado}}
                    </p>
                </div>

                </div>
              </div>
            </div>

            <div class="col-md-4 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Status</h4>

                        <form class="forms-sample">

                            <div class="form-group">
                                <label for="valor">Status Pagamento</label>
                                <select v-model="idStatusPagamento" class="form-select" aria-label="Default select example">
                                    <option :value="item.id" v-for="item in statusPagamento" :key="item.id">{{item.descricao}}</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="valor">Status Pedido</label>
                                <select v-model="idStatusPedido" class="form-select" aria-label="Default select example">
                                    <option :value="item.id" v-for="item in statusPedido" :key="item.id">{{item.descricao}}</option>
                                </select>
                            </div>
                            <button @click.prevent="atualizarPedido" class="btn btn-success btn-sm mx-2">Atualizar</button>
                        </form>

                </div>
              </div>
            </div>

    </div>
</template>

<script>
import axiosApi from '@/axios'
import store from '@/store'
import router from '@/router'
export default {
    data(){
        return{
            pedido:{},
            idStatusPedido: '',
            idStatusPagamento: '',
            statusPedido: [],
            statusPagamento: [],
            itens: [],
            status:{
                pagamento: '',
                pedido: ''
            }
        }
    },

    props:{
        id: Number
    },

    methods:{

        async listarStatusPedido(){
            const response = await axiosApi.get('/admin/status/pedido')
            return response.data.itens
        },

        async listarStatusPagamento(){
            const response = await axiosApi.get('/admin/status/pagamento')
            return response.data.itens
        },

        async detalhePedido(id){
            try {
                const response = await axiosApi.get(`/admin/pedido/${id}`)
                this.pedido = response.data.pedido
            } catch(error){
                await this.$swal('Pedido', error.response.data.erro, 'error')
                router.push({'name': 'ListaPedidos'})
            }
        },

        formatarValor(valor) {
            var valorFormatado = valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
            return valorFormatado;
        },

        async atualizarPedido(){
            try {
                store.commit('setLoading', true)
                let dados = {'id_status_pagamento': this.idStatusPagamento, 'id_status_pedido': this.idStatusPedido}
                await axiosApi.put(`/admin/pedido/${this.id}`, dados)
                this.status.pagamento = this.statusPagamento[this.idStatusPagamento-1].descricao
                this.status.pedido = this.statusPedido[this.idStatusPedido-1].descricao
                this.$swal('Sucesso', 'Pedido atualizado com sucesso', 'success')
            } finally {
                store.commit('setLoading', false)
            }
        }
    },

    async mounted(){
        store.commit('setLoading', true)
        await this.detalhePedido(this.id)
        this.itens = this.pedido.itens
        this.statusPedido = await this.listarStatusPedido()
        this.statusPagamento = await this.listarStatusPagamento()
        this.idStatusPedido = this.pedido.status.id_status_pedido
        this.idStatusPagamento = this.pedido.status.id_status_pagamento

        this.status.pagamento = this.pedido.status.pagamento
        this.status.pedido = this.pedido.status.pedido
        store.commit('setLoading', false)
    }
};
</script>

<style>
</style>